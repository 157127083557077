import React, { useContext, useEffect, useState } from "react";
import "./Shop.css";
import ShopBox from "../ShopBox/ShopBox";
import PopUp from "../PopUp/PopUp";
import Popup2 from "../Popup2/Popup2";
import cnx from "../../Context";
import Popup3 from "../Popup3/Popup3";
import AllData from "../../DatasStatic";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import PopupRegister from "../PopupRegister/PopupRegister";

export default function Shop() {
  let context = useContext(cnx);
  return (
    <div className="shop__container">
      <div className="shop__wrapper">
        <PopUp />
        {context.showCommentP && <Popup3 {...context.AllData} />}
        {context.showPopup2 && <Popup2 {...context.AllData} />}
        {context.showPopupRegister && <PopupRegister />}
        {context.resData
          .filter((item) => {
            if (context.lastValueTag == "") return <ShopBox {...item} />;
            if (
              item.name
                .toLowerCase()
                .includes(context.lastValueTag.toLowerCase())
            ) {
              return item;
            }
          })
          .map((item) => (
            <ShopBox {...item} />
          ))}
      </div>
    </div>
  );
}
