import React, { useContext, useState } from "react";
import "./BascketItem.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import cnx from "../../Context";

import { FaTimes } from "react-icons/fa";

export default function BascketItem(prop) {
  let context = useContext(cnx);

  return context.shopList.map((item) => (
    <div className="backetitem__container">
      <div className="backetitem__container__icon">
        <FaTimes
          onClick={() => {
            let spread = [...context.shopList];

            let filterItem = spread.filter((bagItem) => {
              return bagItem.title !== item.title;
            });

            context.setShopList(filterItem);

            context.setTotalPrice((prev) => prev - +item.price * item.number);

            context.setNumber2((prev) => prev - 1);
          }}
        />
      </div>
      <div className="backetitem__container__information">
        <span className="backetitem__container__information__image">
          <img src={item.img} alt="" />
        </span>
        <span className="backetitem__container__information__text">
          <p>{item.title}</p>
        </span>
      </div>
      <div className="backetitem__container__price">
        <AiOutlinePlus
          onClick={() => {
            let spredData = [...context.shopList];
            spredData.some((data) => {
              if (data.title == item.title) {
                data.number += 1;
                return true;
              }
            });

            context.setShopList(spredData);

            context.setTotalPrice((prev) => prev + +item.price);
          }}
        />
        <span>
          <p>{+item.price * item.number}</p>
          <small>تومان</small>
        </span>
        <AiOutlineMinus
          onClick={() => {
            let spredData = [...context.shopList];
            spredData.some((data) => {
              if (data.title == item.title) {
                data.number -= 1;
                return true;
              }
            });

            context.setShopList(spredData);

            if (item.number < 1) {
              let spread = [...context.shopList];

              let filterItem = spread.filter((bagItem) => {
                return bagItem.title !== item.title;
              });

              context.setShopList(filterItem);

              context.setTotalPrice((prev) => prev - +item.price * item.number);

              context.setNumber2((prev) => prev - 1); 
            }

            if (context.totalPrice == 0) {
              context.setTotalPrice(0);
            } else {
              context.setTotalPrice((prev) => prev - +item.price);
            }
          }}
        />
      </div>
    </div>
  ));
}
