import React, { useContext, useState } from "react";
import "./../Css/MyAccount.css";
import Header from "../../Components/Header/Header";
import Box from "../../Components/Box/Box";
import cnx from "../../Context";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { AiOutlineHome, AiOutlineRight, AiOutlineUser } from "react-icons/ai";

export default function MyAccount() {
  let context = useContext(cnx);

  function clickHandler() {
    localStorage.clear();
    context.setIsLoginUser(false);
    Swal.fire({
      icon: "success",
      text: "با موفقیت از حساب خود خارج شدید",
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  return (
    <>
      <Header />
      <div className="myaccount__container">
        <div className="myaccount__wrapper">
          <div className="myaccount__wrapper__breadcrumb">
            <AiOutlineHome />
            <span>خانه</span>
            <AiOutlineRight />
            <span>myAccount</span>
          </div>
          <div className="myaccount__wrapper__other">
            <h2>MyAccount</h2>
            <div>
              <div className="myaccount__wrapper__other__right">
                <ul>
                  <li>
                    متن تستی
                    <AiOutlineUser />
                  </li>
                  <li>
                    متن تستی
                    <AiOutlineUser />
                  </li>
                  <li>
                    متن تستی
                    <AiOutlineUser />
                  </li>
                  <li>
                    متن تستی
                    <AiOutlineUser />
                  </li>
                  <li>
                    متن تستی
                    <AiOutlineUser />
                  </li>
                  <li>
                    متن تستی
                    <AiOutlineUser />
                  </li>
                  <li>
                    متن تستی
                    <AiOutlineUser />
                  </li>
                </ul>
              </div>
              <div className="myaccount__wrapper__other__left">
                <p>
                  سلام <span>{context.userLoginName}</span> ( نیستید؟؟{" "}
                  <Link to='/' onClick={clickHandler}>خارج شوید</Link>)
                </p>
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box />
    </>
  );
}
