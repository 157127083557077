import React, { useContext, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Popup2.css";
import cnx from "../../Context";
import AllData from "../../DatasStatic";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

// react Icons Start
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { IoPricetagOutline } from "react-icons/io5";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

// react Icons End

export default function Popup2(prop) {
  let context = useContext(cnx);

  //   function Start

  function timesHandler() {
    context.setPopUp2(false);
  }

  function plusHandler() {
    context.setNumber((prev) => prev + 1);
  }

  function minusHandler() {
    context.setNumber((prev) => prev - 1);
    if (context.number == 0) {
      context.setNumber(0);
    }
  }

  function closePopup2() {
    context.setShowPopup2(false);
    context.setNumber(0);
  }

  //   function End

  return (
    <div className="popup2__container">
      <div className="popup2__wrapper">
        <div className="shopbox__wrapper__topIcons">
          <div className="shopbox__wrapper__topIcons__left">
            <img src={context.logo} alt="" />
            <h5>{context.nameData}</h5>
          </div>
        </div>
        <section>
          <div className="popup__wrapper__images2">
            <BsArrowLeft />
            <BsArrowRight />
            <Swiper
              navigation={true}
              loop={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {prop.images.map((item) => (
                <SwiperSlide>
                  <img src={item.src} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="popup__wrapper__text2">
            <div className="popup__wrapper__text2__item0">
              <span>
                <h3>{prop.nameItem}</h3>
              </span>
              <span>
                <p>
                  {+prop.price * prop.number} تومان
                  <IoPricetagOutline />
                </p>
              </span>
            </div>
            <small></small>
            <div className="popup__wrapper__text2__item">
              <div className="popup__wrapper__text2__price__price">
                <div>
                  <p>{prop.nameItem}</p>
                  <span>
                    <p>{prop.price} تومان</p>
                    <p>در فروشگاه</p>
                  </span>
                </div>
                <div>{prop.text}</div>
              </div>
            </div>
            <div className="popup__wrapper__text2__item2">
              <button
                onClick={async () => {
                  let IsInCart = context.shopList.some((bagItem) => {
                    return bagItem.title === prop.title;
                  });

                  context.setNumber((prev) => prev + 1);

                  if (!IsInCart) {
                    let newItemBascket = {
                      id : prop.id,
                      img: prop.img,
                      title: prop.title,
                      price: prop.price,
                      name: prop.nameItem,
                      number: 1,
                    };

                    console.log(prop.id)

                    await context.setShopList((prev) => [
                      ...prev,
                      newItemBascket,
                    ]);

                    context.setTotalPrice((prev) => prev + +prop.price);

                    context.setNumber2((prev) => prev + 1);
                  } else {
                    let spredData = [...context.shopList];
                    spredData.some((item) => {
                      if (item.title == prop.title) {
                        item.number += 1;
                        return true;
                      }
                    });

                    context.setShopList(spredData);

                    context.setTotalPrice((prev) => prev + +prop.price);
                  }
                }}
              >
                اضافه کردن به سبد خرید
              </button>
              <div>
                <AiOutlinePlus onClick={plusHandler} />
                <p>{context.number}</p>
                <AiOutlineMinus onClick={minusHandler} />
              </div>
              {/* <IoHeartOutline />
              <IoBarChartOutline /> */}
            </div>
            <div className="popup__wrapper__text2__item3">
              {/* <p>متن تستی برای نمایش !!</p>
              <p>متن تستی برای نمایش !!</p>
              <p>متن تستی برای نمایش !!</p> */}
            </div>
            {/* <div className="popup__wrapper__text2__item4">
                <FaFacebookF />
                <FaPinterestP />
                <AiOutlineGooglePlus />
                <AiOutlineTwitter />
              </div> */}
          </div>
        </section>
      </div>
      <div className="AllPopup2" onClick={closePopup2}></div>
    </div>
  );
}
