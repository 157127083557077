import React, { useState, useContext, useRef, useEffect } from "react";
import "./PopUp.css";
import cnx from "../../Context";
import Modal from "react-bootstrap/Modal";

// import Icons Start

import { FaTimes } from "react-icons/fa";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import {
  IoHeartOutline,
  IoChatboxOutline,
  IoBookmarkOutline,
  IoShareSocialOutline,
} from "react-icons/io5";
import { MdOutlineInsertEmoticon } from "react-icons/md";

// import Icons End

// import Component or module Start

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

// import Component or module End

export default function PopUp() {
  const context = useContext(cnx);

  return (
    <Modal show={context.show3} onHide={context.handleClose3}>
      <Modal.Body className="modalBody2">
        <div className="popup__wrapper__left">
          <div className="popup__wrapper__left__top">
            <span>
              <img src="./images/img_2.jpg" alt="" />
              <p>نام کاربری</p>
            </span>
            <span>
              <BiDotsHorizontalRounded />
            </span>
          </div>
          <div className="popup__wrapper__left__center">
            <div className="popup__wrapper__left__center__admin">
              <div className="popup__wrapper__left__center__admin__one">
                <img src="./images/img_1.jpg" alt="" />
              </div>
              <div className="popup__wrapper__left__center__admin__two">
                <h5>نام کاربری</h5>
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
                  تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                  آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
                  صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                  راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
                  حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای
                  موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
              </div>
            </div>
            <div className="popup__wrapper__left__center__other">
              <div className="popup__wrapper__left__center__other__one">
                <img src="./images/img_1.jpg" alt="" />
              </div>
              <div className="popup__wrapper__left__center__other__two">
                <h5>نام کاربری</h5>
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                </p>
                <p>متن تستی</p>
              </div>
              <div className="popup__wrapper__left__center__other__three">
                <IoHeartOutline />
              </div>
            </div>
            <div className="popup__wrapper__left__center__other">
              <div className="popup__wrapper__left__center__other__one">
                <img src="./images/img_1.jpg" alt="" />
              </div>
              <div className="popup__wrapper__left__center__other__two">
                <h5>نام کاربری</h5>
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                </p>
                <p>متن تستی</p>
              </div>
              <div className="popup__wrapper__left__center__other__three">
                <IoHeartOutline />
              </div>
            </div>
          </div>
          <div className="popup__wrapper__left__icons">
            <div>
              <span>
                <IoHeartOutline />
                <IoChatboxOutline />
                <IoShareSocialOutline />
              </span>
              <span>
                <IoBookmarkOutline />
              </span>
            </div>
            <h5>40,212 لایک</h5>
            <small>2 روز پیش</small>
          </div>
          <div className="popup__wrapper__left__post">
            <span>
              <MdOutlineInsertEmoticon />
              <input type="text" placeholder="کامنت جدید ..." />
            </span>
            <span>
              <button>پست</button>
            </span>
          </div>
        </div>
        <div className="popup__wrapper__right">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            loop={true}
          >
            <SwiperSlide>
              <img src="./images/img_1.jpg" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="./images/img_1.jpg" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="./images/img_1.jpg" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="./images/img_1.jpg" alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </Modal.Body>
    </Modal>
  );
}
