import React, { useState } from 'react';
import './App.css';
import './Reset.css'
import AllRoutes from './Routes';
import { useRoutes } from 'react-router-dom';
import cnx from './Context';

export default function App() {

  let Routes = useRoutes(AllRoutes);

  // State Context Start

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [showZoom, setShowZoom] = useState(false);
  const [login , setLogin] = useState(false);
  const [search , setSearch] = useState(false);
  const [showCommentP , setShowCommentP] = useState(false);
  const [showPopup2 , setShowPopup2] = useState(false);
  const [shopList , setShopList] = useState([]);
  const [AllData , setAllData] = useState([]);
  const [totalPrice , setTotalPrice] = useState(0);
  const [number, setNumber] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [resData , setResData] = useState([]);
  const [nameData , setNameData] = useState('');
  const [searchValue , setSearchValue] = useState('');
  const [comments , setComments] = useState([]);
  const [searchValue2 , setSearchValue2] = useState('');
  const [logo , setLogo] = useState([]);
  const [userLoginEmail , setUserLoginEmail] = useState('');
  const [userLoginName , setUserLoginName] = useState('');
  const [isLoginUser , setIsLoginUser] = useState(false);
  const [showPopupRegister , setShowPopupRegister] = useState(false);
  const [tags, setTags] = useState([]);
  const [lastValueTag , setLastValueTag] = useState('');

  // State Context End

  return (
    <cnx.Provider value={{
      lastValueTag,
      setLastValueTag,
      tags,
      setTags,
      showPopupRegister,
      setShowPopupRegister,
      isLoginUser,
      setIsLoginUser,
      userLoginEmail,
      setUserLoginEmail,
      userLoginName,
      setUserLoginName,
      logo,
      setLogo,
      show3,
      setShow3,
      searchValue2,
      setSearchValue2,
      comments,
      setComments,
      searchValue,
      setSearchValue,
      handleClose3,
      handleShow3,
      showZoom,
      setShowZoom,
      login,
      setLogin,
      search,
      setSearch,
      showCommentP,
      setShowCommentP,
      showPopup2,
      setShowPopup2,
      shopList,
      setShopList,
      AllData,
      setAllData,
      totalPrice,
      setTotalPrice,
      number,
      setNumber,
      number2,
      setNumber2,
      resData,
      setResData,
      nameData,
      setNameData,
    }}>
      <div className='app'>
        {Routes}
      </div>
    </cnx.Provider>
  )
}
