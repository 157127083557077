import React, { useState, useContext, useRef, useEffect } from "react";
import "./SearchBar.css";
import { IoSearchOutline } from "react-icons/io5";
import { TiTimes } from "react-icons/ti";
import cnx from "../../Context";
import { BsTags } from "react-icons/bs";

export default function SearchBar() {
  let context = useContext(cnx);
  let Ref = useRef();
  let inputRef = useRef();

  function changeHandler(e) {
    context.setSearchValue(e.target.value);
    context.setLastValueTag(e.target.value);
  }

  // useEffect(() => {
  //   console.log(inputRef.current);
  //   context.setLastValueTag(inputRef.current.value);
  //   console.log(context.lastValueTag);
  // }, [context.tags]);

  const addTags = (e) => {
    if (e.key == "Enter" && e.target.value != "") {
      console.log(context.lastValueTag);
      context.setTags([...context.tags, e.target.value]);
      context.setSearchValue("");
    }
  };
  const removeTags = (indexToRimove) => {
    context.setTags(context.tags.filter((_, index) => index != indexToRimove));
    if (context.tags.length == 1) {
      context.setLastValueTag("");
    }
  };

  return (
    <div className="searchbar__container">
      <div className="searchbar__container__tagbox">
        <ul ref={Ref}>
          {context.tags.map((tag, index) => (
            <li key={index}>
              <i
                class="fa-sharp fa-solid fa-circle-xmark"
                onClick={() => removeTags(index)}
              ></i>
              <span>{tag}</span>
            </li>
          ))}
          <input
            type="text"
            placeholder="جستجو..."
            ref={inputRef}
            value={context.searchValue}
            onChange={(e) => changeHandler(e)}
            onKeyUp={(e) => addTags(e)}
          />
        </ul>
      </div>
    </div>
  );
}
