import React, { useRef, useState, useContext, useEffect } from "react";
import "../Css/Checkout.css";
import cnx from "../../Context";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import Swal from "sweetalert2";
import Header from "./../../Components/Header/Header";
import Box from "./../../Components/Box/Box";

export default function Checkout() {
  let context = useContext(cnx);

  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("iran");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Hormozgan");
  const [tel, setTel] = useState("");

  let firstname = useRef();
  let lastname = useRef();

  function clickBtnHandler() {
    const Data = {
      payment_method: "bacs",
      payment_method_title: "Direct Bank Transfer",
      set_paid: true,
      billing: {
        first_name: context.userLoginName,
        last_name: { lastName },
        address_1: { street },
        address_2: "",
        city: { city },
        state: { state },
        postcode: "94103",
        country: { country },
        email: context.userLoginEmail,
        phone: { tel },
      },
      shipping: {
        first_name: context.userLoginName,
        last_name: { lastName },
        address_1: { street },
        address_2: "",
        city: { city },
        state: { state },
        postcode: "94103",
        country: { country },
      },
      line_items: context.shopList.map((item) => {
        return {
          product_id: item.id,
          quantity: item.number,
        };
      }),
    };

    const WooCommerce = new WooCommerceRestApi({
      url: "https://hg-dev.ir/martfury/",
      consumerKey: "ck_656478943eb8338d2b5160ef21ad542abdc2cab2",
      consumerSecret: "cs_d647177c409b9205d69e1c662fac1db6522c4038",
      version: "wc/v3",
    });

    if (context.shopList.length != 0) {
      WooCommerce.post("orders", Data)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
      context.setShopList([]);
      context.setTotalPrice(0);
      context.setNumber2(0);
      Swal.fire({
        icon: "success",
        text: "محصولات مورد نظر شما با موفقیت به درگاه پرداخت منتقل شد",
      });
      window.location.href = "/";
    }
  }

  return (
    <>
      <Header />
      <div className="checkout__container">
        <div className="checkout__wrapper">
          <div className="checkout__wrapper__right">
            <h3>جزئیات صورتحساب</h3>
            <div className="checkout__wrapper__right__one">
              <span className="checkout__wrapper__right__one__firstname">
                <label htmlFor="firstname">نام</label>
                <input type="text" id="firstname" ref={firstname} />
              </span>
              <span className="checkout__wrapper__right__one__lastname">
                <label htmlFor="lastname">نام خانوادگی</label>
                <input
                  type="text"
                  id="lastname"
                  ref={lastname}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </span>
            </div>
            <div className="checkout__wrapper__right__two">
              <label htmlFor="country">نام شرکت (اختیاری)</label>
              <input type="text" id="country" />
            </div>
            <div className="checkout__wrapper__right__three">
              <p>کشور / منطقه</p>
              <select
                name=""
                id=""
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                <option value="ایران">ایران</option>
                <option value="تست 2">تست 2</option>
                <option value="تست 3">تست 3</option>
              </select>
            </div>
            <div className="checkout__wrapper__right__four">
              <label htmlFor="address">آدرس خیابان</label>
              <input
                type="text"
                id="address"
                placeholder="نام خیابان و پلاک خانه"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
              <input
                type="text"
                placeholder="آپارتمان , مجتمع , واحد , و ... (اختیاری)"
              />
            </div>
            <div className="checkout__wrapper__right__five">
              <label htmlFor="city">شهر</label>
              <input
                type="text"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="checkout__wrapper__right__six">
              <p>استان</p>
              <select name="" id="" onChange={(e) => setState(e.target.value)}>
                <option value="هرمزگان">هرمزگان</option>
                <option value="تست 1">تست 1</option>
                <option value="تست 2">تست 2</option>
              </select>
            </div>
            <div className="checkout__wrapper__right__seven">
              <label htmlFor="code">
                کد پستی (بدون فاصله و با اعداد انگلیسی)
              </label>
              <input type="text" id="code" />
            </div>
            <div className="checkout__wrapper__right__eight">
              <label htmlFor="tel">تلفن</label>
              <input
                type="text"
                id="tel"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
              />
            </div>
            <div className="checkout__wrapper__right__nine">
              <label htmlFor="email">آدرس ایمیل</label>
              <input type="text" id="email" />
            </div>
          </div>
          <div className="checkout__wrapper__left">
            <ul>
              <h3>سفارش شما</h3>
              <li>
                <span>محصول</span>
                <span>جمع جزء</span>
              </li>
              <li className="listItem_2">
                {context.shopList.map((item) => (
                  <>
                    <div>
                      <span>
                        {item.title} * {item.number}
                      </span>
                      <span>{item.price}</span>
                    </div>
                  </>
                ))}
                {/* <span>
                {context.shopList.map((item) => {
                  // console.log(item)
                  item.title;
                })}
              </span>
              <span>120 ریال</span> */}
              </li>
              {/* <li>
              <span>جمع جزء</span>
              <span>120 ریال</span>
            </li> */}
              <li>
                <span>حمل و نقل</span>
                <span>حمل و نقل رایگان</span>
              </li>
              <li>
                <span>مجموع</span>
                <span>{context.totalPrice} ریال</span>
              </li>
            </ul>
            <div className="checkout__wrapper__left__btn">
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است
              </p>
              <p>
                <input type="checkbox" />
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است
              </p>
              <div>
                <button onClick={clickBtnHandler}>ثبت سفارش</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box />
    </>
  );
}
