import React, { useContext, useState, useRef, useCallback } from "react";
import "./Login.css";
import cnx from "../../Context";
import Swal from "sweetalert2";
import { AiOutlineUser } from "react-icons/ai";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Link } from "react-router-dom";
import ProgressButton from "react-progress-button";
// import {JSO, Popup} from 'jso';
// import 'core-js/es6/array';

export default function Login() {
  // let ROOTURL = "https://hg-dev.ir/martfury";
  // const RESTROOT = ROOTURL + "/wp-json";
  // const RESTROUTE = RESTROOT + "/wc/v3/tasks/";

  // let jso = new JSO({
  //   providerID: "login/register",
  //   client_id: "su1wQq9c62id",
  //   redirect_url: "http://localhost:3000/",
  //   authorization: ROOTURL + "/oauth/authorize",
  // });

  // const RefNumber = useRef();
  // const RefNumber2 = useRef();
  // const btn = useRef();
  // const btn2 = useRef();

  const RefUsername = useRef();
  const RefPassword = useRef();
  const RefButton = useRef();
  const [valueUsername, setValueUsername] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  // const [buttonState, setButtonState] = useState("");

  let context = useContext(cnx);

  // const [valuePhone, setValuePhone] = useState("");
  // const [valueCode, setValueCode] = useState("");

  // let regexPhone = /^09(1[0-9]|3[0-9]|2[012]|9[01234]|0[123])[0-9]{3}[0-9]{4}$/;

  function clickHandler() {
    context.setLogin(false);
  }

  let data = {
    username: valueUsername,
    password: valuePassword,
  };

  function clickLoginHandler() {
    if (valueUsername.length <= 2) {
      Swal.fire({
        icon: "error",
        text: "نام کاربری شما حداقل باید شامل سه کاراکتر باشد",
      });
    } else if (valuePassword.length < 4) {
      Swal.fire({
        icon: "error",
        text: "کلمه عبور شما باید حداقل شامل چهار کاراکتر باشد",
      });
    } else {
      fetch("https://hg-dev.ir/martfury/wp-json/jwt-auth/v1/token", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.token) {
            context.setIsLoginUser(true);
            window.localStorage.setItem("token", data.token);
            setValueUsername("");
            setValuePassword("");
            context.setLogin(false);
            Swal.fire({
              icon: "success",
              text: "با موفقیت وارد شدید",
            });
            context.setUserLoginEmail(data.user_email);
            context.setUserLoginName(data.user_nicename);
            RefButton.current.classList.remove("rs-btn-loading");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "نام کاربری یا کلمه عبور وارد شده صحیح نمیباشد!!!",
            });
            setValueUsername("");
            setValuePassword("");
          }
        });
    }
  }

  function clickRegisterHandler() {
    context.setLogin(false);
    context.setShowPopupRegister(true);
  }

  // function handleClick() {
  //   setButtonState({ buttonState: "loading" });
  //   if (context.isLoginUser) {
  //     setButtonState({ buttonState: "success" });
  //   }
  //   // this.setState({buttonState: 'loading'})
  //   // make asynchronous call
  //   // setTimeout(() => {
  //   //   this.setState({ buttonState: "success" });
  //   // }, 3000);
  // }

  // function clickBtnLogin() {
  //   if (regexPhone.test(valuePhone) == true) {

  //     RefNumber.current.classList.add("hide");
  //     RefNumber2.current.classList.remove("hide");
  //     btn.current.classList.add("hide");
  //     btn2.current.classList.remove("hide");
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "لطفا شماره تلفن را به صورت صحیح وارد کنید",
  //     });
  //     setValuePhone("");
  //   }
  // }

  return (
    <div className="login__container">
      <div className="login__wrapper">
        <h3>{context.nameData}</h3>
        <div className="login__wrapper__username">
          <AiOutlineUser />
          <input
            type="text"
            placeholder="نام کاربری یا ایمیل : "
            ref={RefUsername}
            value={valueUsername}
            onChange={(e) => {
              setValueUsername(e.target.value);
            }}
          />
        </div>
        <input
          type="password"
          placeholder="کلمه عبور خود را وارد کنید : "
          ref={RefPassword}
          value={valuePassword}
          onChange={(e) => {
            setValuePassword(e.target.value);
          }}
        />
        {/* <button ref={btn} onClick={clickBtnLogin}>
          ورود / ثبت نام
        </button> */}
        {/* <ProgressButton onClick={handleClick} state={buttonState}>
          ورود
        </ProgressButton> */}
        <div className="login__wrapper__btn">
          <span>
            <input type="checkbox" />
            من را به خاطر بسپار
          </span>
          <button onClick={clickLoginHandler}>ورود</button>
          {/* <Button onClick={(e) => clickLoginHandler(e)}>ورود</Button> */}
        </div>
        <div className="login__wrapper__register__btn">
          <p className="register__btn">
            هنوز ثبت نام نکردید؟{" "}
            <span onClick={clickRegisterHandler}>ثبت نام کنید</span>
          </p>
        </div>
      </div>
      <div className="all__Login" onClick={clickHandler}></div>
    </div>
  );
}
