import React, { useContext, useEffect, useState } from "react";
import "./PopupRegister.css";
import cnx from "../../Context";
import Swal from "sweetalert2";

export default function PopupRegister() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  let context = useContext(cnx);
  function closeRegisterPopup() {
    context.setShowPopupRegister(false);
  }

  async function clickBtnRegister() {
    let res = await fetch(
      "https://hg-dev.ir/martfury/api/get_nonce?controller=user&method=register"
    );
    let data = await res.json();
    console.log(data.nonce);

    fetch(
      `https://hg-dev.ir/martfury/api/user/register?username=${username}&user_pass=${password}&nonce=${data.nonce}&notify=both&insecure=cool&email=${email}`,
      {
        method: "POST",
      }
    ).then((data) => {
      console.log(data);
      if (data.status == 200) {
        context.setIsLoginUser(true);
        context.setUserLoginName(username);
        context.setUserLoginEmail(email);
        setUsername("");
        setPassword("");
        setEmail("");
        context.setShowPopupRegister(false);
        Swal.fire({
          icon: "success",
          text: "ثبت نام شما موفقیت آمیز بود",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "ثبت نام شما ناموفق بود!!!",
        });
      }
    });
  }

  return (
    <div className="popupRegister__container">
      <div className="popupRegister__wrapper">
        <h3>ثبت نام</h3>
        <div className="popupRegister__wrapper__username">
          <label htmlFor="register__username">نام کاربری</label>
          <input
            type="text"
            id="register__username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </div>
        <div className="popupRegister__wrapper__email">
          <label htmlFor="register__email">ادرس ایمیل</label>
          <input
            type="email"
            id="register__email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="popupRegister__wrapper__password">
          <label htmlFor="register__password">کلمه عبور</label>
          <input
            type="password"
            id="register__password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <button onClick={clickBtnRegister}>ثبت نام</button>
      </div>
      <div className="popupRegister__other" onClick={closeRegisterPopup}></div>
    </div>
  );
}
