import React, { useRef, useContext, useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";

// import Icons Start
import { FaTimes } from "react-icons/fa";
import { BiChevronDown } from "react-icons/bi";
import { IoReorderThreeOutline } from "react-icons/io5";
import { IoSearchOutline } from "react-icons/io5";
import { IoBasketOutline } from "react-icons/io5";
import { VscAccount } from "react-icons/vsc";

// import Icons End

// Import package or Component Start
import ModalSearch from "../ModalSearch/ModalSearch";
import cnx from "../../Context";
import BascketItem from "../BascketItem/BascketItem";
import Login from "../Login/Login";
import PopupRegister from "../PopupRegister/PopupRegister";
// Import package or Component End

export default function Header(prop) {
  let showNavbarRef = useRef();
  let showListNavbar = useRef();
  let showListBascket = useRef();
  const [redirect, setRedirect] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModalPayment, setShowModalPayment] = useState(false);

  let context = useContext(cnx);

  function clickBarsHandler() {
    showNavbarRef.current.classList.add("active_navbar");
    clickCloseBascket();
  }

  function clickTimesHandler() {
    showNavbarRef.current.classList.remove("active_navbar");
  }

  function clickOpenHandler(e) {
    showListNavbar.current.classList.toggle("active_list_navbar");
  }

  function clickSearchHandler() {
    context.setSearch(true);
  }

  function clickBasketHandler() {
    showListBascket.current.classList.add("active");
    clickTimesHandler();
  }

  function clickCloseBascket() {
    showListBascket.current.classList.remove("active");
  }

  function clickLoginHandler() {
    context.setLogin(true);
  }

  function paymentHandler() {
    if (context.isLoginUser == true) {
      setRedirect(true);
    } else {
      setShowModalPayment(true);
      setRedirect(false);
    }
  }

  function closeAccountHandler() {
    // window.location.reload();
    localStorage.clear();
    context.setIsLoginUser(false);
    Swal.fire({
      icon: "success",
      text: "با موفقیت از حساب خود خارج شدید",
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  const header__sticky = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        header__sticky.current.classList.add("header__shrink");
      } else {
        header__sticky.current.classList.remove("header__shrink");
      }

      return () => window.removeEventListener("scroll");
    });
  }, []);

  return (
    <div className="header__container" ref={header__sticky}>
      <div className="header__wrapper">
        <div className="header__wrapper__shopbar" ref={showListBascket}>
          <span>
            <FaTimes onClick={clickCloseBascket} />
            <p>شما این محصولات را انتخاب کرده اید</p>
          </span>
          <BascketItem />
          <div className="header__wrapper__shopbar__btn1">
            <small>
              مجموع <p>{context.totalPrice}</p> تومان
            </small>
          </div>
          <div className="header__wrapper__shopbar__btn2">
            <Link to={redirect ? "/checkout" : "/"} onClick={paymentHandler}>
              تسویه حساب
            </Link>
          </div>
        </div>
        <div className="header__wrapper__logo">
          <IoReorderThreeOutline onClick={clickBarsHandler} />
          <a href="https://hg-dev.ir/martfury">{context.nameData}</a>
        </div>
        <div className="header__wrapper__icons">
          <IoSearchOutline onClick={clickSearchHandler} />
          <span>
            <p>{context.number2}</p>
            <IoBasketOutline onClick={clickBasketHandler} />
          </span>
          {context.isLoginUser == false ? (
            <VscAccount onClick={clickLoginHandler} />
          ) : (
            <>
              <img
                src="./images/user.png"
                alt=""
                style={{ marginRight: "-1rem" }}
                onClick={() => {
                  handleShow();
                }}
              />
              <Dropdown style={{ height: "30px" }}>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    border: "1px solid #333",
                    height: "30px",
                  }}
                >
                  {context.userLoginName}
                  <BiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-2">
                    <Link to="/account">حساب کاربری</Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-3"
                    onClick={closeAccountHandler}
                  >
                    خروج
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
        <div className="header__wrapper__navbar" ref={showNavbarRef}>
          <FaTimes onClick={clickTimesHandler} />
          <ul className="header__wrapper__navbar__list">
            {context.resData.map((item) => (
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                    onClick={(e) => {
                      context.setTags([e.target.innerHTML]);
                      context.setLastValueTag(e.target.innerHTML);
                    }}
                  >
                    {item.name}
                  </Accordion.Header>
                  <Accordion.Body>
                    {item.categories.map((data) => (
                      <li
                        onClick={(e) => {
                          context.setTags([e.target.innerHTML]);
                          context.setLastValueTag(e.target.innerHTML);
                        }}
                      >
                        {data.name}
                      </li>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </ul>
        </div>
        <div className="header__wrapper__modal__search">
          {context.search && <ModalSearch />}
          {context.login && <Login />}
        </div>
      </div>
      {showModalPayment == true && (
        <div className="payment__container">
          <div className="payment__wrapper">
            <h3>برای تسویه حساب نیاز است ابتدا لاگین کرده باشید</h3>
            <button
              onClick={() => {
                setShowModalPayment(false);
                context.setLogin(true);
              }}
            >
              لاگین
            </button>
          </div>
          <div
            className="payment__other"
            onClick={() => {
              setShowModalPayment(false);
            }}
          ></div>
        </div>
      )}
    </div>
  );
}
