import Home from './Pages/Js/Home.jsx';
import Checkout from './Pages/Js/Checkout.jsx';
import MyAccount from './Pages/Js/MyAccount.jsx';

let AllRoutes = [
    {path : '/' , element : <Home />},
    {path : '/checkout' , element : <Checkout />},
    {path : '/account' , element : <MyAccount />},
]

export default AllRoutes;