import React, { useContext } from "react";
import "./Popup3.css";

import { IoHeartOutline } from "react-icons/io5";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoChatboxOutline } from "react-icons/io5";
import { IoExpandOutline } from "react-icons/io5";
import { IoBookmarkOutline } from "react-icons/io5";
import { MdOutlineInsertEmoticon } from "react-icons/md";
import cnx from "../../Context";

export default function Popup3(prop) {
  let context = useContext(cnx);

  function clickHandler() {
    context.setShowCommentP(false);
  }

  let htmlStr = prop.text;
  const newStr = htmlStr.replace(/(<([^>]+)>)/gi, "");

  return (
    <div className="popup3__container">
      <div className="popup3__wrapper">
        <div className="popup3__wrapper__topIcons">
          <div className="shopbox__wrapper__topIcons__left">
            <img src={context.logo} alt="" />
            <h5>{context.nameData}</h5>
          </div>
        </div>
        <div className="popup3__wrapper__center">
          <div className="popup__wrapper__left__center__admin">
            <div className="popup__wrapper__left__center__admin__one">
              <img src={context.logo} alt="" />
            </div>
            <div className="popup__wrapper__left__center__admin__two">
              {newStr}
            </div>
          </div>
          {context.comments.map((comment) => (
            <div className="popup__wrapper__left__center__other">
              <div className="popup__wrapper__left__center__other__one">
                <img src="./images/user.png" alt="" />
              </div>
              <div className="popup__wrapper__left__center__other__two">
                <p>{comment.content.rendered.replace(/(<([^>]+)>)/gi, "")}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="popup3__wrapper__icons">
          <div>
            <span>
              <IoHeartOutline />
              <IoChatboxOutline />
              <IoShareSocialOutline />
            </span>
            <span>
              <IoBookmarkOutline />
            </span>
          </div>
          {/* <h5>40,212 لایک</h5>
          <small>2 روز پیش</small> */}
        </div>
        <div className="popup3__wrapper__post">
          <span>
            <MdOutlineInsertEmoticon />
            <input type="text" placeholder="کامنت جدید ..." />
          </span>
          <span>
            <button>پست</button>
          </span>
        </div>
      </div>
      <div className="All__popup3" onClick={clickHandler}></div>
    </div>
  );
}
