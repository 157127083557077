import React, { useEffect, useState, useContext } from "react";
import "./ShopBox.css";
import cnx from "../../Context";
import Popup2 from "../Popup2/Popup2";
import InfiniteScroll from "react-infinite-scroll-component";

// import Icons Start

import { AiOutlineHeart, AiOutlineSave } from "react-icons/ai";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { BiZoomIn, BiDotsVerticalRounded } from "react-icons/bi";
import { FaRegCommentAlt, FaTelegramPlane } from "react-icons/fa";

import { IoHeartOutline } from "react-icons/io5";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoChatboxOutline } from "react-icons/io5";
import { IoExpandOutline } from "react-icons/io5";
import { IoBookmarkOutline } from "react-icons/io5";
import { IoPricetagOutline } from "react-icons/io5";
import { BsBagPlus } from "react-icons/bs";
import AllData from "../../DatasStatic";

// import Icons End

export default function ShopBox(prop, index) {
  let context = useContext(cnx);

  const [number, setNumber] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);

  function timesHandler() {
    context.setShowZoom(false);
    setNumber((prev) => 0);
  }

  function plusNumberHandler() {
    setNumber((prev) => prev + 1);
  }

  function minusNumberHandler() {
    setNumber((prev) => prev - 1);
    if (number == 0) {
      setNumber(0);
    }
  }

  function shopPopUp2() {
    context.setShowPopup2(true);
  }

  function clickDotHandler() {
    setShowPopUp(true);
  }

  function clickHeartHandler(e) {
    e.target.classList.toggle("redHeart");
  }

  function clickAllComments() {
    context.setShowCommentP(true);
  }

  function closeShowPopUp() {
    setShowPopUp(false);
  }

  let htmlStr = prop.description;
  const newStr = htmlStr.replace(/(<([^>]+)>)/gi, "");

  return (
    <section id="shopBoxSection" key={prop.id}>
      <div className="shopbox__container">
        <div className="shopbox__wrapper">
          <div className="shopbox__wrapper__topIcons">
            <div className="shopbox__wrapper__topIcons__left">
              <img src={context.logo} alt="" />
              <h5>{context.nameData}</h5>
            </div>
            <div className="shopbox__wrapper__topIcons__right">
              {/* <BiDotsVerticalRounded onClick={clickDotHandler} /> */}
              <BsBagPlus
                onClick={async () => {
                  console.log(prop);
                  let IsInCart = context.shopList.some((bagItem) => {
                    return bagItem.title === prop.title;
                  });

                  context.setNumber((prev) => prev + 1);

                  if (!IsInCart) {
                    let newItemBascket = {
                      id: prop.id,
                      img: prop.images[0].src,
                      title: prop.slug,
                      price: prop.price,
                      name: prop.name,
                      number: 1,
                    };

                    console.log(prop.id);

                    await context.setShopList((prev) => [
                      ...prev,
                      newItemBascket,
                    ]);

                    context.setTotalPrice((prev) => prev + +prop.price);

                    context.setNumber2((prev) => prev + 1);
                  } else {
                    let spredData = [...context.shopList];
                    spredData.some((item) => {
                      if (item.title == prop.title) {
                        item.number += 1;
                        return true;
                      }
                    });

                    context.setShopList(spredData);

                    context.setTotalPrice((prev) => prev + +prop.price);
                  }
                }}
              />
            </div>
            {showPopUp && (
              <div className="shopbox__wrapper__topIcons__popup__container">
                <div className="shopbox__wrapper__topIcons__popup__wrapper">
                  <p>اضافه کردن به سبد خرید</p>
                  <p>موارد تستی جهت نمایش</p>
                  <p>موارد تستی جهت نمایش</p>
                  <p>موارد تستی جهت نمایش</p>
                </div>
                <div
                  className="shopbox__wrapper__topIcons__popup__other"
                  onClick={closeShowPopUp}
                ></div>
              </div>
            )}
          </div>
          <img
            src={prop.images[0].src}
            alt=""
            onClick={async () => {
              let PopUpData = {
                id: prop.id,
                img: prop.images[0].src,
                title: prop.slug,
                nameItem: prop.name,
                price: prop.price,
                number: 1,
                text: prop.short_description,
                images: prop.images,
                categories: prop.categories,
              };

              await context.setAllData(PopUpData);

              console.log(context.AllData);

              context.setShowPopup2(true);
            }}
          />
          <div className="shopbox__wrapper__icons">
            <div className="shopbox__wrapper__icons__left">
              <IoHeartOutline onClick={(e) => clickHeartHandler(e)} />
              <IoChatboxOutline
                onClick={async () => {
                  console.log(prop);
                  let PopUpData = {
                    img: prop.images[0].src,
                    title: prop.slug,
                    nameItem: prop.name,
                    price: prop.price,
                    number: 1,
                    text: prop.short_description,
                    images: prop.images,
                    categories: prop.categories,
                  };

                  await context.setAllData(PopUpData);

                  context.setShowCommentP(true);
                }}
              />
              {/* <IoShareSocialOutline /> */}
            </div>
            <div className="shopbox__wrapper__icons__right">
              {/* <IoBookmarkOutline /> */}
              <IoExpandOutline onClick={shopPopUp2} />
            </div>
          </div>
          <div className="shopbox__wrapper__text">
            <div>
              <span className="shopbox__wrapper__text__right">
                <h2>{prop.name}</h2>
                <h3>{prop.nameItem}</h3>
              </span>
              <span className="shopbox__wrapper__text__left">
                <p>
                  {prop.price} تومان
                  <IoPricetagOutline />
                </p>
              </span>
            </div>
            {newStr}
            <p className="end__P" onClick={clickAllComments}>
              مشاهده تمامی {context.comments.length} کامنت
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
