import React from "react";
import "./Box.css";
import { TbTruckDelivery } from "react-icons/tb";
import { AiOutlineGift, AiOutlineCreditCard } from "react-icons/ai";

export default function Box() {
  return (
    <div className="Box__container">
      <p>ساخته شده با فروشگاه ساز هزارشاپ</p>
    </div>
  );
}
