import React, { useEffect, useContext, useState } from "react";
import "../Css/Home.css";
import Header from "../../Components/Header/Header";
import SearchBar from "../../Components/SearchBar/SearchBar";
import Shop from "../../Components/Shop/Shop";
import Box from "../../Components/Box/Box";
import cnx from "../../Context";

export default function Home() {
  const context = useContext(cnx);

  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://hg-dev.ir/martfury/wp-json/wc/v3/products?per_page=50&consumer_key=ck_851fb58e718b8ce532d60f351d9fe28e49878bd4&consumer_secret=cs_bb83ac0315910cf81c1134a4948963855a25d0e1"
      );
      const data = await response.json();
      return data;
    }

    getData().then(async (res) => {
      await context.setResData(res);
    });

    async function getData2() {
      const response = await fetch("https://hg-dev.ir/martfury/wp-json");
      const data = await response.json();
      return data;
    }

    getData2().then(async (res) => {
      await context.setLogo(res.site_icon_url);
      await context.setNameData(res.name);
    });

    async function getData3() {
      const response = await fetch(
        "https://hg-dev.ir/martfury/wp-json/wp/v2/comments"
      );
      const data = await response.json();
      return data;
    }

    getData3().then(async (res) => {
      await context.setComments(res);
    });

    // async function getData() {
    //   const response = await fetch(
    //     "https://hg-dev.ir/martfury/wp-json/wc/store/v1/products&consumer_key=ck_851fb58e718b8ce532d60f351d9fe28e49878bd4&consumer_secret=cs_bb83ac0315910cf81c1134a4948963855a25d0e1"
    //   );
    //   const data = await response.json();
    //   return data;
    // }
    // getData().then(async (res) => {
    //   console.log(res);
    // });
  });

  return (
    <section className="home__page">
      <Header />
      <SearchBar />
      <Shop />
      <Box />
    </section>
  );
}
