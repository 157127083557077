import React, { useState, useContext } from "react";
import "./ModalSearch.css";
import cnx from "../../Context";
import ShopBox from "../ShopBox/ShopBox";

import { IoSearchOutline } from "react-icons/io5";

export default function ModalSearch() {
  let context = useContext(cnx);
  const [search, setSearch] = useState("");

  function clickHandler() {
    context.setSearch(false);
  }

  return (
    <div className="search__container">
      <div className="search__wrapper">
        <IoSearchOutline />
        <input
          type="text"
          placeholder="جستجو..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <button
          onClick={() => {
            context.setTags([...context.tags, search]);
            context.setLastValueTag(search);
            context.setSearch(false);
          }}
        >
          جستجو
        </button>
      </div>
      <div className="all__search" onClick={clickHandler}></div>
    </div>
  );
}
